import {
  IoSettingsOutline,
  IoShirtOutline,
  IoBodyOutline,
  IoSwapHorizontalOutline,
  IoHappyOutline,
} from "react-icons/io5";
import AOS from "aos";
import "aos/dist/aos.css";

import { useEffect, useState } from "react";

const SolutionCard = ({ solution, isLarge }) => {
  const [aosDisabled, setAosDisabled] = useState(false);
  useEffect(() => {
    if (!aosDisabled) {
      AOS.init({
        duration: 1000,
        once: true,
        offset: 200,
      });
    }
  }, [aosDisabled]);
  return (
    <div
      className={`bg-[#121212] hover:bg-custom-blue-3 text-white flex flex-col justify-center hover:scale-[1.01] transition-transform duration-[300ms] rounded-xl h-auto ${
        isLarge ? "w-auto" : "w-full "
      } py-[10px] px-[18px]`}
      data-aos={!aosDisabled ? "zoom-in" : ""}
    >
      <div className="flex flex-col h-full justify-center text-center items-center lg:text-left lg:items-start ">
        <img
          src={solution.imgSrc}
          alt={solution.imgAlt}
          className={`${
            isLarge
              ? `${
                  solution.n === 4 || solution.n === 5
                    ? "h-[10rem] w-[11rem]"
                    : "h-[12rem] w-[13rem]"
                } `
              : "h-[8rem] w-[8rem] sm:h-[9rem] sm:w-[9rem]"
          } `}
        />
        <h1 className="text-[20px] sm:text-[23px] lg:text-[31px] font-bold px-4">
          {solution.title}
        </h1>
        <p className="text-[12px] sm:text-[14px] lg:text-[21px] mt-5 mb-2 break-words overflow-hidden font-bold px-4">
          {solution.description}
        </p>
      </div>
    </div>
  );
};

const Virtuon_Solutions = () => {
  const solutions = [
    {
      imgSrc: "Virtuon_Solutions/int.png",
      imgAlt: "personalized_shopping_experience",
      icon: <IoShirtOutline className="text-[5rem]" />,
      title: "Personalized Shopping",
      description:
        "We deliver personalized shopping experience, virtual try-ons  suggestions for user preferences and measurements.",
      n: 1,
    },
    {
      imgSrc: "Virtuon_Solutions/meas.png",
      imgAlt: "accurate_body_sizing",
      icon: <IoBodyOutline className="text-[5rem]" />,
      title: "Body Sizing",
      description:
        "Utilizes machine learning to analyze body measurements and ensure precise size recommendations, reducing fit-related issues.",
      n: 2,
    },
    {
      imgSrc: "Virtuon_Solutions/cus.png",
      imgAlt: "customer_satisfaction",
      icon: <IoHappyOutline className="text-[5rem]" />,
      title: "Cost Efficiency",
      description:
        "Our virtual trial room software delivers advanced technology at a low cost, accessible to any brand.",
      n: 3,
    },
    {
      imgSrc: "Virtuon_Solutions/cus.png",
      imgAlt: "customer_satisfaction",
      icon: <IoHappyOutline className="text-[5rem]" />,
      title: "Enhanced Customer Engagement",
      description:
        "Enhances customer engagement through interactive try-ons, fostering greater confidence in purchases and reducing product return rates.",
      n: 4,
    },
    {
      imgSrc: "Virtuon_Solutions/int.png",
      imgAlt: "seamless_integration",
      icon: <IoSwapHorizontalOutline className="text-[5rem]" />,
      title: "Seamless Integration",
      description:
        "Easily integrates into existing e-commerce websites, providing an intuitive virtual trial room without disrupting platform functionality.",
      n: 5,
    },
  ];

  return (
    <section className="bg-black pt-[5rem] pb-[3rem] mx-2 sm:mx-10 rounded-[2.5rem] my-[4rem]">
      <div className="flex flex-col items-center justify-center gap-2 px-[0.5rem] sm:px-[2.5rem]">
        <div className="w-full text-center">
          <h1 className="text-white text-[1rem] sm:text-[3rem] lg:text-[4rem] font-bold mb-[2.5rem]">
            Solutions for Virtual Trial
          </h1>
        </div>

        <div className="hidden lg:grid grid-cols-3 gap-[1rem] justify-center w-full">
          {solutions.slice(0, 3).map((solution, index) => (
            <SolutionCard key={index} solution={solution} isLarge={true} />
          ))}
        </div>

        <div className="hidden lg:grid grid-cols-2 gap-[1rem] justify-between w-full">
          {solutions.slice(3).map((solution, index) => (
            <SolutionCard key={index} solution={solution} isLarge={true} />
          ))}
        </div>

        <div className="lg:hidden flex flex-wrap gap-[1rem]">
          {solutions.map((solution, index) => (
            <SolutionCard key={index} solution={solution} isLarge={false} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Virtuon_Solutions;
