import { useRef, useEffect, useState } from "react";
import emailjs from "emailjs-com";
import { IoIosMail } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import { IoIosCall } from "react-icons/io";
import { FaInstagram, FaLinkedin, FaFacebook } from "react-icons/fa";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

const Virtuon_Contact = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    AOS.init({
      duration: 1000,
      once: true,
      offset: window.innerWidth > 768 ? 200 : 50,
    });
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ck645d5",
        "template_pkq3raa",
        form.current,
        "xoBuhOvY_putN17FR"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Message sent successfully!");
        },
        (error) => {
          console.log(error.text);
          alert("Failed to send message.");
        }
      );
  };

  return (
    <section className="bg-gray-100 flex flex-col items-center justify-center">
      <div
        className="min-h-[32rem] w-full bg-cover bg-center bg-no-repeat flex items-center justify-center"
        style={{
          backgroundImage: "url('./UPES.jpg')",
        }}
      >
        <div className="text-center text-white backdrop-blur-[5px] p-8 rounded-lg">
          <h2 className="text-lg font-bold uppercase mb-2">Get in Touch</h2>
          <h1 className="text-4xl sm:text-5xl font-bold mb-6">
            We’d love to connect! Feel free to ask us anything.
          </h1>
          <p className="text-base sm:text-lg">
            We’re confident Virtuon will be the right choice for you.
          </p>
        </div>
      </div>

      <div className="flex flex-col md:flex-row rounded-2xl items-center justify-center m-2 p-2 sm:w-[80%] gap-5">
        <div
          className="sm:relative bottom-[120px] w-full sm:w-[40rem]"
          data-aos="fade-up"
        >
          <form
            ref={form}
            onSubmit={sendEmail}
            className="bg-white flex flex-col gap-10 p-5 rounded-2xl"
          >
            <h2 className="text-2xl font-bold m-4 text-black">Contact Us</h2>
            <div className="grid grid-cols-2 gap-4">
              <label className="relative w-full my-3">
                <input
                  type="text"
                  name="first_name"
                  className="block py-3 text-black w-full text-sm bg-transparent border-b-2 border-black appearance-none focus:outline-none peer p-1"
                  required
                />
                <span className="absolute text-black text-lg duration-300 left-2 top-2 peer-focus:text-sm peer-focus:-translate-y-5 peer-focus:px-1 peer-valid:text-sm peer-valid:-translate-y-5 peer-valid:px-1">
                  First Name
                </span>
              </label>
              <label className="relative w-full my-3">
                <input
                  type="text"
                  name="last_name"
                  className="block py-3 text-black w-full text-sm bg-transparent border-b-2 border-black appearance-none focus:outline-none peer p-1"
                  required
                />
                <span className="absolute text-black text-lg duration-300 left-2 top-2 peer-focus:text-sm peer-focus:-translate-y-5 peer-focus:px-1 peer-valid:text-sm peer-valid:-translate-y-5 peer-valid:px-1">
                  Last Name
                </span>
              </label>
              <label className="relative w-full my-3">
                <input
                  type="email"
                  name="email"
                  className="block py-3 text-black w-full text-sm bg-transparent border-b-2 border-black appearance-none focus:outline-none peer p-1"
                  required
                />
                <span className="absolute text-black text-lg duration-300 left-2 top-2 peer-focus:text-sm peer-focus:-translate-y-5 peer-focus:px-1 peer-valid:text-sm peer-valid:-translate-y-5 peer-valid:px-1">
                  Email
                </span>
              </label>
              <label className="relative w-full my-3">
                <input
                  type="text"
                  name="contact"
                  className="block py-3 text-black w-full text-sm bg-transparent border-b-2 border-black appearance-none focus:outline-none peer p-1"
                  required
                />
                <span className="absolute text-black text-lg duration-300 left-2 top-2 peer-focus:text-sm peer-focus:-translate-y-5 peer-focus:px-1 peer-valid:text-sm peer-valid:-translate-y-5 peer-valid:px-1">
                  Contact No
                </span>
              </label>
            </div>
            <div className="flex flex-col w-full my-5">
              <label className="relative w-full my-3">
                <input
                  type="text"
                  name="message"
                  className="block py-3 text-black w-full text-sm bg-transparent border-b-2 border-black appearance-none focus:outline-none peer p-1"
                  required
                />
                <span className="absolute text-black text-lg duration-300 left-2 top-2 peer-focus:text-sm peer-focus:-translate-y-5 peer-focus:px-1 peer-valid:text-sm peer-valid:-translate-y-5 peer-valid:px-1">
                  Message
                </span>
              </label>
            </div>
            <button
              type="submit"
              className="border-2 border-black p-2 rounded-lg w-[5rem] text-black mx-auto transition-scale 
            hover:scale-[1.1] duration-[300ms] font-bold text-[14px] hover:text-white hover:bg-black"
            >
              SEND
            </button>
          </form>
        </div>

        <div
          className=" bg-white rounded-2xl p-10 shadow-md w-full md:w-[20rem] sm:h-auto mx-auto"
          data-aos="fade-up"
        >
          <div className="flex gap-[1.2rem] sm:gap-3 border-b-2 py-[10px]">
            <Link
              to="https://www.facebook.com/Virtuon"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="p-1 rounded-full hover:bg-[#1877F2] focus:bg-[#1877F2] hover:shadow-[0_0_24px_0_#1877F2] focus:shadow-[0_0_24px_0_#1877F2] transition duration-300 text-xl text-[#1877F2] hover:text-white focus:text-white">
                <FaFacebook />
              </div>
            </Link>
            <Link
              to="https://www.linkedin.com/company/virtuon-technologies/?originalSubdomain=in"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="p-1 rounded-full hover:bg-[#0077B5] focus:bg-[#0077B5] hover:shadow-[0_0_24px_0_#0077B5] focus:shadow-[0_0_24px_0_#0077B5] transition duration-300 text-xl text-[#0077B5] hover:text-white focus:text-white">
                <FaLinkedin />
              </div>
            </Link>
            <Link
              to="https://www.instagram.com/virtuon_technologies/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="p-1 rounded-full hover:bg-[#E4405F] focus:bg-[#E4405F] hover:shadow-[0_0_24px_0_#E4405F] focus:shadow-[0_0_24px_0_#E4405F] transition duration-300 text-xl text-[#E4405F] hover:text-white focus:text-white">
                <FaInstagram />
              </div>
            </Link>
          </div>
          <div className="flex items-center border-b-2 py-[25px] ">
            <IoIosMail className="mr-5 text-[20px] text-red-500" />
            <p className="text-[1rem] ">Adarsh.Tripathi@virtuon.in</p>
          </div>
          <div className="flex items-center  border-b-2 py-[25px] ">
            <IoIosCall className="mr-5 text-[20px] text-red-500" />
            <p className=" text-[1rem]">+91 8826374006</p>
          </div>
          <div className="flex items-center border-b-2 py-[25px]">
            <FaLocationDot className="mr-5 text-[35px] text-red-500" />
            <p className=" text-[1.2rem]">
              Runway Incubator Office, UPES, Bidholi, Dehradun, Uttarakhand,
              India
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Virtuon_Contact;
