import { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const Virtuon_Works = () => {
  const [aosDisabled, setAosDisabled] = useState(false);
  useEffect(() => {
    if (!aosDisabled) {
      AOS.init({
        duration: 1000,
        once: true,
        offset: 200,
      });
    }
  }, [aosDisabled]);

  const steps = [
    {
      imgSrc: "Virtuon_HowItWorks/virtuon_select.png",
      imgAlt: "Browse and Select Your Styles",
      title: "Browse and Select Your Styles",
    },
    {
      imgSrc: "Virtuon_HowItWorks/virtuon_body_size2.png",
      imgAlt: "Body Size Recommendations",
      title: "Body Size Recommendations",
    },
    {
      imgSrc: "Virtuon_HowItWorks/virtuon_try.png",
      imgAlt: "Virtual Try-On",
      title: "Virtual Try-On",
    },
  ];

  return (
    <section
      id="how-it-works"
      className="bg-[white] text-black sm:p-10 my-[4rem]"
    >
      <div className="mx-auto">
        <div className="p-[20px] flex flex-col md:flex-row">
          <div
            className=" w-full md:w-[60%] md:ml-5 lg:p-[35px]"
            data-aos={!aosDisabled ? "fade-right" : ""}
          >
            <h2 className="text-[20px] md:text-[28px] lg:text-4xl font-bold leading-tight">
              Experience <span className="text-[#00B5F4]">Virtuon's</span> Trial
              Room. Browse, Try, and Find Your Perfect Fit!
            </h2>
            <div className="flex flex-col gap-6 mt-6 text-[10px] md:text-[14px] text-gray-700">
              <p>
                Explore a wide selection of styles, outfits, and accessories in
                our Virtual Trial Room. Easily filter by occasion, color, or
                fabric, and find options tailored to your taste. Enter your body
                measurements for personalized sizing recommendations.
              </p>

              <p>
                Now comes the exciting part—seeing how your chosen clothing will
                look on you! Using Augmented Reality, our Virtual Trial Room
                overlays the selected outfit onto your live image or an uploaded
                picture, giving you a realistic view from all angles.
              </p>
            </div>

            <div className="flex flex-col gap-4 mt-6">
              {steps.map((step, index) => (
                <div key={index} className="flex items-start">
                  <div className="flex flex-col gap-2 text-black p-4 rounded-lg w-full">
                    <div className="flex items-center">
                      <img
                        src={step.imgSrc}
                        alt={step.imgAlt}
                        className="w-[3rem] h-[3.2rem] mr-4 object-contain"
                      />
                      <h3 className="text-lg sm:text-xl font-bold">
                        {step.title}
                      </h3>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Video on right for larger screens */}
          <div className="sm:w-[50%] m-auto lg:ml-[7rem]" data-aos="fade-left">
            <video
              src="Virtuon_HowItWorks/Howitworks.mp4"
              autoPlay
              muted
              playsInline
              loop
              className="w-[20rem] h-[25rem] object-contain hover:scale-[1.05] transition-scale duration-[300ms] rounded-lg md:w-[25rem] md:h-[30rem] lg:w-[30rem] lg:h-[40rem]"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Virtuon_Works;
