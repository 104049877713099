import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import { useRef, useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const Virtuon_Supportedby = () => {
  const [aosDisabled, setAosDisabled] = useState(false);
  useEffect(() => {
    if (!aosDisabled) {
      AOS.init({
        duration: 1000,
        once: true,
        offset: 200,
      });
    }
  }, [aosDisabled]);

  const swiperRef = useRef(null);

  const partners = [
    {
      logo: "Supports/msfs.png",
      name: "Microsoft for Startups",
      description:
        "Founders Hub accelerates innovation with access to industry-leading AI services, expert guidance, and essential technology. Build a future-proof startup with our support.",
      to: "https://www.microsoft.com/en-in/",
      height: "7rem",
    },
    {
      logo: "Supports/upes.png",
      name: "UPES",
      description:
        "A private university offering industry-focused education in engineering, management, law, and more.",
      to: "https://www.upes.ac.in/",
      height: "4.5rem",
    },
    {
      logo: "Supports/runway.png",
      name: "Runway Incubator",
      description:
        "Helps startups transition from ideas to successful businesses with mentorship, grants, investments, and workspace.",
      to: "https://www.runwayincubator.com/",
      height: "7rem",
    },
  ];

  return (
    <>
      <section className="bg-gray-50 py-20 lg:hidden my-[4rem]">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-4xl font-bold mb-[4rem] text-black">
            Our Collaborations
          </h2>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            loop={true}
            modules={[Navigation]}
            className="mySwiper"
            onSwiper={(swiper) => (swiperRef.current = swiper)}
          >
            {partners.map((supporter, index) => (
              <SwiperSlide key={index}>
                <div
                  className="bg-tertiary p-1 rounded-2xl w-[90%] mx-auto flex items-center justify-between"
                  data-aos={!aosDisabled ? "zoom-in" : ""}
                >
                  <button
                    className="bg-card-color text-white rounded-full p-2 hover:bg-blue-600 transition duration-300 h-[40px]"
                    onClick={() => {
                      if (swiperRef.current) {
                        swiperRef.current.slidePrev();
                      } else {
                        console.error("Swiper reference is not set");
                      }
                    }}
                  >
                    &#10094;
                  </button>
                  <Link
                    to={supporter.to}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="h-[10rem] flex flex-col items-center justify-center gap-6 rounded-lg p-4 hover:scale-[1.05] transition-transform duration-[300ms]">
                      <img
                        src={supporter.logo}
                        alt={`${supporter.name} Logo`}
                        style={{ height: supporter.height }}
                        className="w-full mx-auto object-contain"
                      />
                    </div>
                  </Link>
                  <button
                    className="bg-card-color text-white rounded-full p-2 hover:bg-blue-600 transition duration-300 h-[40px]"
                    onClick={() => {
                      if (swiperRef.current) {
                        swiperRef.current.slideNext();
                      } else {
                        console.error("Swiper reference is not set");
                      }
                    }}
                  >
                    &#10095;
                  </button>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>

      <section className="hidden lg:block bg-gray-50 py-20">
        <div className="container mx-auto text-center p-4 sm:p-0">
          <h2 className="text-[3.5rem] font-bold mb-8">Supported by</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
            {partners.map((supporter, index) => (
              <Link
                key={index}
                to={supporter.to}
                target="_blank"
                rel="noopener noreferrer"
                data-aos="zoom-in"
              >
                <div className="h-[10rem] flex flex-col items-center justify-center gap-6 rounded-lg p-4 hover:scale-[1.05] transition-transform duration-[300ms]">
                  <img
                    src={supporter.logo}
                    alt={`${supporter.name} Logo`}
                    style={{ height: supporter.height }}
                    className="w-full mx-auto object-contain"
                  />
                </div>
              </Link>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Virtuon_Supportedby;
