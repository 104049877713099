import Virtuon_Works from "../components/Virtuon_Works";
import Virtuon_Solutions from "../components/Virtuon_Solutions";
import Virtuon_Benefits from "../components/Virtuon_Benefits";
import Virtuon_Brands from "../components/Virtuon_Brands";
import Virtuon_Hero from "../components/Virtuon_Hero";
import Virtuon_Supportedby from "../components/Virtuon_Supportedby";
import Virtuon_Stats from "../components/Virtuon_Stats";

const Virtuon_Home = () => {
  return (
    <>
      <Virtuon_Hero></Virtuon_Hero>
      <Virtuon_Brands></Virtuon_Brands>
      <Virtuon_Solutions></Virtuon_Solutions>
      <Virtuon_Works></Virtuon_Works>
      <Virtuon_Stats></Virtuon_Stats>
      <Virtuon_Supportedby></Virtuon_Supportedby>
      <Virtuon_Benefits></Virtuon_Benefits>
    </>
  );
};

export default Virtuon_Home;
