import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import CountUp from "react-countup";
import AOS from "aos";
import "aos/dist/aos.css";

const Virtuon_Stats = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [aosDisabled, setAosDisabled] = useState(false);

  useEffect(() => {
    if (!aosDisabled) {
      AOS.init({
        duration: 600,
        once: true,
        offset: 100,
      });
    }
  }, [aosDisabled]);

  const benefits = [
    {
      imgSrc: "Virtuon_Stats/returnsbw.png",
      imgSrc2: "Virtuon_Stats/returnsblw.png",
      imgAlt: "Reduced Returns",
      title: "Reduced Returns",
      num: 50,
    },
    {
      imgSrc: "Virtuon_Stats/conversion2bw.png",
      imgSrc2: "Virtuon_Stats/conversion2blw.png",
      imgAlt: "Increased Conversions",
      title: "Increased Conversions",
      num: 30,
    },
    {
      imgSrc: "Virtuon_Stats/co2_2bw.png",
      imgSrc2: "Virtuon_Stats/co2_2blw.png",
      imgAlt: "Less CO2 Emissions",
      title: "Less CO2 Emissions",
      num: 20,
    },
    {
      imgSrc: "Virtuon_Stats/shoppingbw.png",
      imgSrc2: "Virtuon_Stats/shoppingblw.png",
      imgAlt: "Engagement Boost",
      title: "Engagement Boost",
      num: 280,
    },
  ];

  return (
    <section
      className="bg-black p-[3rem] sm:py-[5rem] mx-2 sm:mx-10 rounded-[2.5rem] my-[4rem]"
      ref={ref}
    >
      <div className="flex flex-col items-center justify-center ">
        <p className="text-center text-white font-bold">WHY CHOOSE US?</p>
        <h1 className="text-center text-white  text-[1rem] sm:text-[3rem] font-bold">
          Reasons to choose our platform
        </h1>
      </div>
      <div className="flex flex-wrap gap-[1rem] justify-center mt-6">
        {benefits.map((benefit, index) => (
          <div
            className="flex flex-col gap-4 items-center bg-[#121212] hover:bg-custom-blue-3 text-white hover:text-black 
             hover:scale-[1.05] transition-transform duration-[300ms] rounded-xl h-auto p-6 w-full md:w-[15rem] 
             lg:w-[20rem]"
            key={index}
            data-aos={!aosDisabled ? "zoom-in" : ""}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            <img
              src={hoveredIndex === index ? benefit.imgSrc2 : benefit.imgSrc}
              alt={benefit.imgAlt}
              className="w-[3.5rem] h-[3.7rem] object-contain"
            />
            <div className="flex flex-col gap-[1px]">
              <h3 className="text-[1.2rem] lg:text-[1.5rem] font-bold text-center ">
                {index === 0 || index === 2 ? "-" : "+"}
                {inView && (
                  <CountUp
                    start={0}
                    end={benefit.num}
                    duration={5}
                    useEasing={true}
                    separator=","
                  />
                )}
                %
              </h3>
              <h3 className="text-[1.2rem] lg:text-[1.7rem] font-bold text-center">
                {benefit.title}
              </h3>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Virtuon_Stats;
