import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import "../styles/style.css";

import { Autoplay } from "swiper/modules";

const Virtuon_Brands = () => {
  const [hoveredSlide, setHoveredSlide] = useState(null);

  const images = [
    "anime.jfif",
    "jex.jfif",
    "boteek.jfif",
    "hemp.jfif",
    "raw.jpeg",
  ];

  return (
    <section className="bg-white bg-center bg-cover py-16">
      <div className=" mx-auto p-2 text-center">
        <h2 className="text-4xl font-bold mb-[4rem] text-black">
          Our Collaborations
        </h2>
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 0,
            disableOnInteraction: false,
          }}
          freeMode={true}
          speed={6500}
          loop={true}
          modules={[Autoplay]}
          className="h-auto"
          style={{
            transitionTimingFunction: "linear",
          }}
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
          effect="slide"
        >
          {images.map((item, index) => (
            <SwiperSlide
              key={index}
              className="relative transition-all duration-300"
            >
              <img
                src={`Collabs/${item}`}
                alt={`Brand ${index + 1}`}
                className="w-full h-auto object-contain rounded-lg transition-transform duration-300"
              />

              {hoveredSlide === index && (
                <div className="absolute inset-0 bg-[#e5e7eb] shadow-lg rounded-xl transition-opacity duration-300 opacity-100 z-10 p-8 flex flex-col items-center justify-center">
                  <img
                    src={`Collabs/${item}`}
                    alt={`Brand ${index + 1}`}
                    className="w-[10rem] h-[10rem] object-contain mb-2 rounded-full bg-white "
                  />

                  <p className="text-black text-center text-sm">
                    We offer personalized shopping experiences that let
                    customers try products and explore styles, helping them make
                    decisions.
                  </p>
                </div>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Virtuon_Brands;
