import { BrowserRouter, Routes, Route } from "react-router-dom";

import Virtuon_Header from "./components/Virtuon_Header";
import Virtuon_Footer from "./components/Virtuon_Footer";
import Virtuon_Contact from "./pages/Virtuon_Contact";
import Virtuon_Home from "./pages/Virtuon_Home";
import Virtuon_AboutUs from "./pages/Virtuon_AboutUs";

function App() {
  return (
    <BrowserRouter>
      <Virtuon_Header></Virtuon_Header>
      <Routes>
        <Route path="/" element={<Virtuon_Home />}></Route>
        <Route path="/contact" element={<Virtuon_Contact />}></Route>
        <Route path="/about" element={<Virtuon_AboutUs />}></Route>
      </Routes>
      <Virtuon_Footer></Virtuon_Footer>
    </BrowserRouter>
  );
}

export default App;
