import { Swiper, SwiperSlide } from "swiper/react";
import { FaStar } from "react-icons/fa";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import { Pagination, Autoplay, Navigation } from "swiper/modules";
import { useEffect, useState } from "react";

import AOS from "aos";
import "aos/dist/aos.css";

const testimonials = [
  {
    id: 1,
    qoute: "Improved Brand Loyalty",
    text: "Our customers appreciate the convenience and accuracy of the virtual trial room. It has not only increased their confidence in our products but also strengthened their loyalty to our brand. We couldn't be happier with the results.",
    name: "Hemang Wason",
    role: "CEO, Jexlin Designs",
    image: "Collabs/jex_owner.jpeg",
  },
  {
    id: 2,
    qoute: "Enhanced Customer Experience",
    text: "We've seen a significant boost in customer engagement with the virtual trial room. Our customers love trying on clothes virtually, and it's led to a marked increase in time spent on our website. The innovation has really set us apart from the competition.",
    name: "Dev Taneja",
    role: "CEO, Anime Devta",
    image: "Collabs/anime_owner.jpeg",
  },
  {
    id: 3,
    qoute: "Increased Conversion Rates",
    text: "Since integrating the virtual trial room, our conversion rates have skyrocketed. Customers are more confident in their purchases, resulting in fewer returns and more repeat business. It's been a game-changer for our online sales.",
    name: "Siddharth Gupta",
    role: "CEO, Indian Hempstore",
    image: "Collabs/hemp_owner.jpeg",
  },
  {
    id: 4,
    qoute: "Cutting-Edge Technology",
    text: "Incorporating the virtual trial room has positioned our brand at the forefront of fashion technology. It's not just a tool, it's an experience that elevates our brand's image and keeps us ahead of the curve.",
    name: "Devashish Raj",
    role: "CEO, Boteek",
    image: "Collabs/boteek_owner.jpeg",
  },

  {
    id: 5,
    qoute: "Reduced Return Rates",
    text: "The virtual trial room has drastically reduced our return rates. Customers can now see how clothes fit before making a purchase, which has led to fewer disappointed customers and lower operational costs.",
    name: "Mayank",
    role: "CEO, Raw",
    image: "Collabs/raw2.jpeg",
  },
];

const Virtuon_AboutUs = () => {
  const [scrollY, setScrollY] = useState(0);
  const handleScroll = () => {
    setScrollY(window.scrollY);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      offset: 200,
    });
  }, []);
  const [hoveredCard, setHoveredCard] = useState(null);
  return (
    <section className="bg-white flex flex-col items-center justify-center">
      <div className="p-5 my-10">
        <div className="bg-slate-300 flex flex-col md:flex-row items-center justify-between w-full p-5 gap-[2rem] rounded-2xl ">
          <div className="rounded-2xl w-full md:w-1/2" data-aos="fade-right">
            <img
              src="founder.jpeg"
              alt="founder"
              className="object-cover rounded-2xl h-full w-full"
            ></img>
          </div>
          <div
            className="flex flex-col gap-4 p-4 justify-center w-full md:w-1/2 lg:gap-[2rem]"
            data-aos="fade-left"
          >
            <div className="flex flex-col md:gap-[1.5rem] gap-[2.5rem]">
              <div>
                <h1 className="text-[30px] font-bold md:text-[35px] lg:text-[3.5rem] ">
                  Adarsh Tripathi
                </h1>
                <h1 className="text-[20px] font-bold md:text-[30px] lg:text-[2rem] mt-auto ml-1">
                  Founder & CEO
                </h1>
              </div>

              <p className=" md:text-[12.5px] lg:text-[1.2rem] font-bold text-gray-500">
                "As an entrepreneur with over 2 years of experience, I'm
                committed to revolutionizing shopping through AI and the
                metaverse. With a keen understanding of emerging technologies, I
                aim to blend the physical and virtual worlds seamlessly. This
                empowers users to experience products in immersive and
                personalized ways. Join me as we redefine the future of retail
                and beyond."
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-auto p-2 text-center  w-full">
        <div className="p-5">
          <p className="text-[1.5rem] lg:text-[3rem] font-bold">Testimonials</p>
        </div>

        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 1500,
            disableOnInteraction: true,
          }}
          loop={true}
          pagination={{
            clickable: true,
            enabled: false,
          }}
          modules={[Autoplay, Pagination, Navigation]}
          className="p-4 w-full"
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
        >
          {testimonials.map(({ id, text, name, role, qoute, image }) => (
            <SwiperSlide key={id} className="px-5">
              <div
                className={`relative flex flex-col justify-center items-start gap-[35px] rounded-2xl shadow-md p-5 ${
                  hoveredCard === id ? "shadow-lg" : ""
                } h-[26rem] lg:h-[28rem] mb-[3rem]`}
                onMouseEnter={() => setHoveredCard(id)}
                onMouseLeave={() => setHoveredCard(null)}
              >
                <div
                  className={`absolute left-0 h-full w-full bg-custom-blue-3 transition-all duration-[300ms] z-10 rounded-2xl ${
                    hoveredCard === id ? "top-0" : "top-[calc(115%)]"
                  }`}
                ></div>

                <div className="z-20">
                  <div className="flex gap-1 text-yellow-500">
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                  </div>
                </div>

                <div className="flex flex-col z-20">
                  <h2
                    className={`text-xl font-bold ${
                      hoveredCard === id ? "text-white" : "text-black"
                    } mb-4 text-left`}
                  >
                    "{qoute}"
                  </h2>
                  <p
                    className={`text-[15px] sm:text-20px ${
                      hoveredCard === id ? "text-white" : "text-black"
                    }  lg:text-[1rem] overflow-hidden text-left`}
                  >
                    {text}
                  </p>
                </div>

                <div className="flex items-center z-20 ">
                  <img
                    src={image}
                    alt={name}
                    className="w-[4rem] h-[4rem] rounded-full object-cover"
                  />
                  <div className="ml-3">
                    <p
                      className={` ${
                        hoveredCard === id ? "text-white" : "text-black"
                      } font-bold text-[1.2rem] text-start`}
                    >
                      {name}
                    </p>
                    <p
                      className={` ${
                        hoveredCard === id ? "text-white" : "text-black"
                      } font-bold text-[0.8rem] text-start`}
                    >
                      {role}
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Virtuon_AboutUs;
