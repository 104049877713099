import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const Virtuon_Benefits = () => {
  const [aosDisabled, setAosDisabled] = useState(false);
  useEffect(() => {
    if (!aosDisabled) {
      AOS.init({
        duration: 1000,
        once: true,
        offset: 200,
      });
    }
  }, [aosDisabled]);

  const benefits = [
    {
      imgSrc: "Virtuon_Benefits/returns.png",
      imgAlt: "Reduced Returns",
      title: "Reduced Returns",
    },
    {
      imgSrc: "Virtuon_Benefits/conversions.png",
      imgAlt: "Increased Conversions",
      title: "Increased Conversions",
    },
    {
      imgSrc: "Virtuon_Benefits/co2.png",
      imgAlt: "Less CO2 Emissions",
      title: "Less CO2 Emissions",
    },
    {
      imgSrc: "Virtuon_Benefits/shopping.png",
      imgAlt: "Effortless Shopping",
      title: "Effortless Shopping",
    },
  ];

  return (
    <section
      id="benefits"
      className="bg-white text-black sm:p-10 mb-5 my-[4rem]"
    >
      <div className="mx-auto">
        <div className="flex flex-col md:flex-row">
          <div
            data-aos="fade-right"
            className="hidden my-auto md:block sm:w-[50%] lg:ml-[5rem]"
          >
            <video
              src="Virtuon_Benefits/benefits.mov"
              autoPlay
              muted
              playsInline
              loop
              className="w-[20rem] h-[25rem] object-contain hover:scale-[1.05] transition-scale duration-[300ms] rounded-lg md:w-[25rem] md:h-[30rem] lg:w-[30rem] lg:h-[40rem]"
            />
          </div>

          <div
            data-aos={!aosDisabled ? "fade-left" : ""}
            className="p-[20px] my-auto w-full md:w-[60%] lg:mr-10 space-y-3"
          >
            <h2 className="text-[20px] md:text-[28px] lg:text-4xl font-bold leading-tight">
              Discover the Future of Fashion with{" "}
              <span className="text-[#00B5F4]">Virtuon's</span> Virtual Trial
              Room. Try Before You Buy, Anywhere, Anytime!
            </h2>
            <div className="flex flex-col gap-6 my-6 text-[10px] md:text-[14px] text-gray-700">
              <p>
                Welcome to Virtuon's Virtual Trial Room, where you can explore a
                personalized collection of styles and outfits. Easily filter by
                occasion, color, or fabric, and input your measurements for
                tailored sizing recommendations.
              </p>
              <p>
                With Augmented Reality, Virtuon lets you see your selected
                clothing in a 360-degree view, overlaying it onto a live image
                or photo. Try before you buy from home, making shopping easier
                and more fun than ever!
              </p>
            </div>

            <div className="grid grid-cols-2 gap-[5px]">
              {benefits.map((benefit, index) => (
                <div key={index} className="flex items-start">
                  <div className="flex flex-col gap-2 text-black p-4 rounded-lg w-full">
                    <div className="flex items-center">
                      <img
                        src={benefit.imgSrc}
                        alt={benefit.imgAlt}
                        className="w-[3rem] h-[3.2rem] object-contain"
                      />
                      <h3 className="text-[12px] sm:text-sm font-bold">
                        {benefit.title}
                      </h3>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="block md:hidden m-auto sm:w-[50%]">
            <video
              src="Virtuon_Benefits/benefits.mov"
              autoPlay
              muted
              playsInline
              loop
              className="w-[20rem] h-[25rem] object-contain hover:scale-[1.05] transition-scale duration-[300ms] rounded-lg md:w-[25rem] md:h-[30rem] lg:w-[30rem] lg:h-[40rem] ml-auto"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Virtuon_Benefits;
