import React from "react";
import Spline from "@splinetool/react-spline";

const Virtuon_Hero = () => {
  return (
    <section className="relative bg-white">
      <div className="min-h-[800px] p-4 flex flex-col lg:flex-row justify-center items-center relative">
        <video
          src="hero.mp4"
          autoPlay
          muted
          playsInline
          loop
          className="absolute inset-0 w-full h-full object-cover z-0"
        />

        <div className="absolute inset-0 bg-black opacity-[30%] z-1"></div>

        <div className="relative z-10 flex flex-col justify-center items-center p-4 lg:pl-10 gap-4">
          <h2
            className="font-black text-[70px] sm:text-[100px] lg:text-[150px] mb-4 leading-tight text-center "
            style={{
              fontFamily: "Concert One",
              fontweight: 400,
              fontstyle: "normal",
              lineHeight: 0.92,
            }}
          >
            <span className="text-custom-blue-3">TRY</span>{" "}
            <span className="text-white">BEFORE</span> <br />{" "}
            <span className="text-[55px] sm:text-[80px] lg:text-[120px]">
              <span className="text-white">YOU</span>{" "}
              <span className="text-custom-blue-3">BUY</span> <br />{" "}
            </span>
          </h2>
        </div>
      </div>
    </section>
  );
};

export default Virtuon_Hero;

/*  <div className="absolute inset-0 flex justify-center 
items-center  h-full z-20 opacity-[60%]">
   <Spline scene="https://prod.spline.design/
EnvVcmQBzxhd3tsU/scene.splinecode" />
 </div>
 */
