import { Link } from "react-router-dom";
import { TfiAlignJustify } from "react-icons/tfi";
import { useState } from "react";

const Virtuon_Header = () => {
  const [isVisible, setIsVisible] = useState(false);

  function handleClick() {
    setIsVisible(!isVisible);
  }

  return (
    <header className="bg-white">
      <nav className="sticky top-0 bg-white my-4 mx-12  lg:mx-20 z-10">
        <div className="flex justify-between items-center">
          <Link to="/" className="ml-5">
            <img
              src="logo.png"
              className="w-[70px] h-[50px] sm:w-[90px] sm:h-[75px]"
              alt="logo"
            />
          </Link>

          <ul className="hidden sm:flex space-x-8 text-black font-bold text-[18px]">
            <li>
              <Link
                to="/"
                className="transition duration-300 hover:text-custom-blue-3 hover:scale-105 hover:rounded-lg p-2"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                className="transition duration-300 hover:text-custom-blue-3 hover:scale-105 hover:rounded-lg p-2"
              >
                About Us
              </Link>
            </li>

            <li>
              <Link
                to="/contact"
                className="transition duration-300 hover:text-custom-blue-3 hover:scale-105 hover:rounded-lg p-2"
              >
                Contact
              </Link>
            </li>
          </ul>

          <button
            className="sm:hidden p-2"
            onClick={handleClick}
            aria-label="Toggle Menu"
          >
            <TfiAlignJustify className="text-black text-[16px]" />
          </button>
        </div>

        <div
          className={`${
            isVisible ? "flex" : "hidden"
          } flex-col gap-6 p-6 font-bold items-center bg-white w-full sm:hidden transition-all duration-300 ease-in-out`}
        >
          <Link
            className="transition duration-300 hover:text-custom-blue-3 hover:scale-105 hover:rounded-lg p-2"
            to="/"
            onClick={() => setIsVisible(false)}
          >
            Home
          </Link>
          <Link
            className="transition duration-300 hover:text-custom-blue-3 hover:scale-105 hover:rounded-lg p-2"
            to="/about"
            onClick={() => setIsVisible(false)}
          >
            About Us
          </Link>

          <Link
            className="transition duration-300 hover:text-custom-blue-3 hover:scale-105 hover:rounded-lg p-2"
            to="/contact"
            onClick={() => setIsVisible(false)}
          >
            Contact
          </Link>
        </div>
      </nav>
    </header>
  );
};

export default Virtuon_Header;
