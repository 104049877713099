import { Link } from "react-router-dom";
import { FaInstagram, FaLinkedin, FaFacebook } from "react-icons/fa";
import { useState } from "react";

const Virtuon_Footer = () => {
  return (
    <footer className="bg-[#31343b] text-white py-6 w-full">
      <div className="mx-auto px-8 flex flex-col sm:flex-row items-center sm:h-[100px]">
        <div className="m-2 flex flex-col justify-center items-center gap-2">
          <div className="flex flex-col gap-[0.5rem] items-center justify-center lg:ml-[20px] lg:mt-[20px]">
            <div>
              <img
                src="logo_1.png"
                className="w-[150px] h-[80px] sm:w-[90px] sm:h-[70px] "
                alt="logo.png"
              ></img>
            </div>
            <div className="flex gap-[1.2rem] sm:gap-3 mb-2">
              <Link
                to="https://www.facebook.com/Virtuon"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="p-1 rounded-full hover:bg-[#1877F2] focus:bg-[#1877F2] hover:shadow-[0_0_24px_0_#1877F2] focus:shadow-[0_0_24px_0_#1877F2] transition duration-300 text-xl text-[#1877F2] hover:text-white focus:text-white">
                  <FaFacebook />
                </div>
              </Link>
              <Link
                to="https://www.linkedin.com/company/virtuon-technologies/?originalSubdomain=in"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="p-1 rounded-full hover:bg-[#0077B5] focus:bg-[#0077B5] hover:shadow-[0_0_24px_0_#0077B5] focus:shadow-[0_0_24px_0_#0077B5] transition duration-300 text-xl text-[#0077B5] hover:text-white focus:text-white">
                  <FaLinkedin />
                </div>
              </Link>
              <Link
                to="https://www.instagram.com/virtuon_technologies/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="p-1 rounded-full hover:bg-[#E4405F] focus:bg-[#E4405F] hover:shadow-[0_0_24px_0_#E4405F] focus:shadow-[0_0_24px_0_#E4405F] transition duration-300 text-xl text-[#E4405F] hover:text-white focus:text-white">
                  <FaInstagram />
                </div>
              </Link>
              <Link
                to="https://x.com/VirtuonTech"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div
                  className="p-1 rounded-full hover:bg-black focus:bg-black hover:shadow-[0_0_24px_0_black] focus:shadow-[0_0_24px_0_white] transition duration-0 text-xl "
                  // onMouseEnter={() => setImageSrc("wbg.png")}
                  // onMouseLeave={() => setImageSrc("bbg.jpg")}
                  // onFocus={() => setImageSrc("wbg.png")}
                  // onBlur={() => setImageSrc("bbg.jpg")}
                >
                  {/* <FaTwitter /> */}
                  <img
                    //src={imageSrc}
                    src="x2.png"
                    alt="X logo"
                    className="w-5 h-5 filter invert"
                  />
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div className="flex gap-[1rem] sm:gap-3 font-bold sm:ml-[14%] md:ml-[18%] lg:ml-[28%] text-[12px] sm:mt-[5rem] lg:mt-[5rem]">
          <Link
            to="/"
            className="text-[15px] sm:text-[18px] transition-scale duration-300 hover:text-custom-blue-3 hover:scale-105 hover:rounded-lg p-2"
          >
            Home
          </Link>
          <Link
            to="/about"
            className="text-[15px] sm:text-[18px] transition-scale duration-300 hover:text-custom-blue-3 hover:scale-105  hover:rounded-lg p-2"
          >
            About Us
          </Link>

          <Link
            to="/contact"
            className="text-[15px] sm:text-[18px] transition-scale duration-300 hover:text-custom-blue-3 hover:scale-105  hover:rounded-lg p-2"
          >
            Contact
          </Link>
        </div>
      </div>

      <div className="text-center text-[12px] mt-6 border-t border-gray-700 p-3">
        <p>© 2023, Virtuon Technologies Pvt. Ltd. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Virtuon_Footer;
